import styled from 'styled-components';
import React, { forwardRef } from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const ContentContainer = forwardRef<HTMLDivElement, Props>(({ children, className = '' }, ref) => (
  <StyledContainer className={className} ref={ref}>
    {children}
  </StyledContainer>
));

const StyledContainer = styled.div`
  position: relative;
  padding: 1rem;
  background: ${props => props.theme.colors.grey25};
  border-top: 1px solid ${props => props.theme.colors.grey100};
`;

ContentContainer.displayName = 'ContentContainer';

export default ContentContainer;
