import styled from 'styled-components';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { statusOverviewsHashSelector } from 'modules/uptime/selectors';
import { fetchStatusOverviewsAction } from 'modules/uptime';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

interface Props {
  shouldFetch: boolean;
  resetCounter: () => void;
}

const TableStatusOverview: React.FC<Props> = ({ shouldFetch, resetCounter }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const type = PaginationType.ADMIN_STATUS_OVERVIEW;
  const hash = useSelector(statusOverviewsHashSelector);

  const sendRequest = useCallback(
    () => dispatch(fetchStatusOverviewsAction()).finally(resetCounter),
    [dispatch, resetCounter]
  );

  useEffect(() => {
    if (shouldFetch) sendRequest();
  }, [shouldFetch, resetCounter, sendRequest]);

  const getFractionColorClass = useCallback((fraction: string) => {
    const [numerator, denominator] = fraction.split('/').map(Number);
    const fractionValue = numerator / denominator;
    if (fractionValue === 1) {
      return 'bg-success';
    } else if (fractionValue < 1) {
      return 'bg-danger';
    }
    return '';
  }, []);

  return (
    <DataTable paginationType={type} totalAmount={0} sendRequest={sendRequest} containerClassName="border-top-0">
      {Object.keys(hash || {}).map((tenant: string) =>
        Object.keys(hash?.[tenant] || {}).map((portfolio: string, portfolioIndex: number) =>
          hash[tenant][portfolio].map((item, index, arr) => (
            <StyledTR key={`${tenant}_${portfolio}_${item.scenario}`}>
              {!portfolioIndex && !index && (
                <td rowSpan={Object.values(hash[tenant]).reduce((sum, arr) => sum + arr.length, 0)}>{tenant || '-'}</td>
              )}
              {!index && <td rowSpan={arr.length}>{portfolio || '-'}</td>}

              <td>{item.scenario || '-'}</td>
              <td
                className={classNames({
                  'bg-success': item.gis_latest_data_recent_enough,
                  'bg-danger': !item.gis_latest_data_recent_enough,
                })}
              >
                {item.gis_latest_data_timestamp || '-'}
              </td>
              <td
                className={classNames({
                  'bg-success': item.meter_latest_data_timestamp_recent_enough,
                  'bg-danger': !item.meter_latest_data_timestamp_recent_enough,
                })}
              >
                {item.meter_latest_data_timestamp || '-'}
              </td>
              <td
                className={classNames({
                  'bg-success': item.last_etl_run_successful,
                  'bg-danger': !item.last_etl_run_successful,
                })}
              >
                {item.etl_latest_time_finished || '-'}
              </td>
              <td
                className={classNames({
                  'bg-success': item.last_network_loading_run_successful,
                  'bg-danger': !item.last_network_loading_run_successful,
                })}
              >
                {item.network_loading_latest_time_finished || '-'}
              </td>
              <td
                className={classNames({
                  'bg-success': item.last_map_update_successful,
                  'bg-danger': !item.last_map_update_successful,
                })}
              >
                {item.map_latest_update || '-'}
              </td>
              <td
                className={classNames('text-center', {
                  'bg-success': item.last_network_loading_run_recent_enough,
                  'bg-danger': !item.last_network_loading_run_recent_enough,
                })}
              >
                {item.last_network_loading_run_recent_enough ? 'Yes' : 'No'}
              </td>

              <td className={classNames('text-center', getFractionColorClass(item.success_rate))}>
                {item.success_rate || '-'}
              </td>
            </StyledTR>
          ))
        )
      )}
    </DataTable>
  );
};

const StyledTR = styled.tr`
  td {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
`;

export default TableStatusOverview;
