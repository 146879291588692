import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const paginationHandlersHookFactory = ({ paginationSelectorFactory, setPaginationAction, PaginationType = {} }) => {
  return ({ type }: { type: Type.PaginationType }) => {
    const dispatch: Shared.CustomDispatch = useDispatch();
    const { filters }: Layouts.Filters = useSelector(paginationSelectorFactory(type));

    const handleSelectChange = useCallback(
      (option: Type.SelectOption, meta: Type.SelectActionMetaBase) => {
        const value = Array.isArray(option) && option.length ? option.map(i => i.value) : option?.value;
        const sideEffects = (() => {
          if (type === PaginationType.ADMIN_TEST_HISTORY && meta.name === 'tenant') {
            return { portfolio: null, scenario: null };
          }
          if (type === PaginationType.ADMIN_TEST_HISTORY && meta.name === 'portfolio') {
            return { scenario: null };
          }
          if (type === PaginationType.ADMIN_VERSION_DETAILS && meta.name === 'tenant') {
            return { portfolio: null };
          }
          return {};
        })();

        dispatch(
          setPaginationAction({
            type,
            modifier: { filters: { ...filters, ...sideEffects, [meta.name]: value || null }, offset: 0 },
          })
        );
      },
      [dispatch, type, filters]
    );

    const handleCheckboxClick = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
          setPaginationAction({
            type,
            modifier: { filters: { ...filters, [event.currentTarget.name]: event.currentTarget.checked }, offset: 0 },
          })
        );
      },
      [type, dispatch, filters]
    );

    const handleSwitchClick = useCallback(
      (name: string, value: boolean) => {
        dispatch(
          setPaginationAction({
            type,
            modifier: { filters: { ...filters, [name]: value }, offset: 0 },
          })
        );
      },
      [type, dispatch, filters]
    );

    const handleInputChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { type: inputType, name, value } = event.target;
        const nextValue = value && inputType === 'number' ? Number(value) : value;
        dispatch(
          setPaginationAction({
            type,
            modifier: { filters: { ...filters, [name]: nextValue }, offset: 0 },
          })
        );
      },
      [type, dispatch, filters]
    );

    return { filters, handleSelectChange, handleCheckboxClick, handleSwitchClick, handleInputChange };
  };
};

export default paginationHandlersHookFactory;
