import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { usePaginationHandlers } from 'hooks';
import { resetReduxStateAction } from 'modules/uptime';
import { DataTableTemplate, ContentContainer, ContentCard } from '@utiligize/shared/components';
import SelectMeterDataIngestionTenant from './SelectMeterDataIngestionTenant';
import ChartMeterDataIngestion from './ChartMeterDataIngestion';
import TableMeterDataIngestion from './TableMeterDataIngestion';
import { PaginationType } from 'constants/index';

const ViewMeterDataIngestion: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.ADMIN_METER_DATA_INGESTION;
  const { filters, handleSelectChange } = usePaginationHandlers({ type });

  // reset history table for proper chart loading state
  useEffect(
    () => () => {
      dispatch(resetReduxStateAction({ meterDataIngestionCount: 0, meterDataIngestionItems: null }));
    },
    [dispatch]
  );

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={4}>
          <SelectMeterDataIngestionTenant name="tenant" value={filters?.tenant || null} onChange={handleSelectChange} />
        </Col>
      </Row>

      <>
        <ContentContainer>
          <ContentCard>
            <ChartMeterDataIngestion />
          </ContentCard>
        </ContentContainer>

        <TableMeterDataIngestion />
      </>
    </DataTableTemplate>
  );
};

export default ViewMeterDataIngestion;
