import styled from 'styled-components';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isSidebarOpenSelector } from 'modules/layouts/selectors';
import { isRouteActiveSelectorFactory } from 'modules/router/selectors';
import { Button } from 'components/_common';
import PopoverUserSettings from './PopoverUserSettings';
import { Routes } from 'constants/index';
import { IconCheckCircle, IconArchive } from '@utiligize/shared/resources';

const Navigation: React.FC = () => {
  const isSidebarOpen = useSelector(isSidebarOpenSelector);
  const isPageFunctionalityTestsSelector = useMemo(
    () => isRouteActiveSelectorFactory(Routes.FunctionalityTests, false),
    []
  );
  const isPageFunctionalityTests = useSelector(isPageFunctionalityTestsSelector);

  return (
    <StyledContainer className="main-header navbar" $isSidebarOpen={isSidebarOpen}>
      <StyledList>
        {isPageFunctionalityTests && (
          <>
            <li>
              <Button
                icon={<IconCheckCircle />}
                labelKey="Tests"
                linkProps={{ to: Routes.FunctionalityTests, exact: true }}
              />
            </li>
            <li>
              <Button
                icon={<IconArchive />}
                labelKey="History"
                linkProps={{ to: Routes.FunctionalityTestsHistory, exact: true }}
                marginLeft
              />
            </li>
          </>
        )}
      </StyledList>
      <div className="ml-auto d-flex align-items-center flex-shrink-0">
        <PopoverUserSettings />
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.nav<{ $isSidebarOpen: boolean }>`
  &&&&& {
    padding: calc(10 / 14 * 1rem);
    background: ${props => props.theme.colors.white};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1020;
    flex-wrap: nowrap;
    ${({ $isSidebarOpen }) => !$isSidebarOpen && `margin-left: 60px!important`};
  }
`;

const StyledList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  padding-right: 8px;
  flex-shrink: 0;
`;

export default Navigation;
