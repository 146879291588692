import styled from 'styled-components';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { testOverviewsHashSelector } from 'modules/uptime/selectors';
import { fetchTestOverviewsAction, runTenantTestsAction } from 'modules/uptime';
import { Button, DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconSkipForward } from '@utiligize/shared/resources';

interface Props {
  shouldFetch: boolean;
  resetCounter: () => void;
}

const TableTestOverview: React.FC<Props> = ({ shouldFetch, resetCounter }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const type = PaginationType.ADMIN_TEST_OVERVIEW;
  const hash = useSelector(testOverviewsHashSelector);

  const sendRequest = useCallback(
    () => dispatch(fetchTestOverviewsAction()).finally(resetCounter),
    [dispatch, resetCounter]
  );

  useEffect(() => {
    if (shouldFetch) sendRequest();
  }, [shouldFetch, resetCounter, sendRequest]);

  const getFractionColorClass = useCallback((fraction: string) => {
    const [numerator, denominator] = fraction.split('/').map(Number);
    const fractionValue = numerator / denominator;
    if (fractionValue === 1) {
      return 'bg-success';
    } else if (fractionValue < 1) {
      return 'bg-danger';
    }
    return '';
  }, []);

  const renderCell = (value: string) => (
    <td className={classNames('text-center', getFractionColorClass(value || ''))}>{value || '-'}</td>
  );

  const handleRunTestsBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const tenant: string | null = event.currentTarget.getAttribute('data-tenant');
      if (tenant) dispatch(runTenantTestsAction(tenant));
    },
    [dispatch]
  );

  return (
    <DataTable paginationType={type} totalAmount={0} sendRequest={sendRequest}>
      {Object.keys(hash || {}).map((tenant: string) =>
        Object.keys(hash?.[tenant] || {}).map((portfolio: string, portfolioIndex: number) => {
          const tenantRowSpan = Object.values(hash[tenant]).reduce((sum, arr) => sum + arr.length, 0);
          return hash[tenant][portfolio].map((item, index, arr) => {
            const renderTenantCell = !portfolioIndex && !index;
            return (
              <StyledTR key={tenant + portfolio + item.scenario}>
                {renderTenantCell && <td rowSpan={tenantRowSpan}>{tenant || '-'}</td>}
                {!index && <td rowSpan={arr.length}>{portfolio || '-'}</td>}

                <td>{item.scenario || '-'}</td>
                {renderCell(item.map)}
                {renderCell(item.data_quality)}
                {renderCell(item.network_loading)}
                {renderCell(item.investment)}
                {renderCell(item.customer)}
                {renderCell(item.n_1)}
                {renderCell(item.co2e)}
                {renderCell(item.setup)}
                {renderCell(item.new_load)}
                {renderCell(item.download_reports)}
                {renderCell(item.maintenance_and_planning)}
                {renderCell(item.reinvestment)}
                {renderTenantCell && (
                  <td rowSpan={tenantRowSpan} className="text-right">
                    <Button
                      tooltipKey="Run tests"
                      icon={<IconSkipForward />}
                      data-tenant={tenant}
                      onClick={handleRunTestsBtnClick}
                      size="small"
                    />
                  </td>
                )}
              </StyledTR>
            );
          });
        })
      )}
    </DataTable>
  );
};

const StyledTR = styled.tr`
  td {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
`;

export default TableTestOverview;
