import axios from 'axios';
import { generatePath } from 'react-router';
import Routes from './routes';
export * from './storage';

export const AssetLifeAPI = axios.create({
  baseURL: `${process.env.REACT_APP_ASSETLIFE_API_URL}/`,
});

export const DefaultRoute = generatePath(Routes.FunctionalityTests);

// Each table pagination and controls meta stored to redux separately
export enum PaginationType {
  // Uptime
  ADMIN_STATUS_OVERVIEW = 'ADMIN_STATUS_OVERVIEW',
  ADMIN_TEST_OVERVIEW = 'ADMIN_TEST_OVERVIEW',
  ADMIN_FUNCTIONALITY_TESTS = 'ADMIN_FUNCTIONALITY_TESTS',
  ADMIN_TEST_HISTORY = 'ADMIN_TEST_HISTORY',
  ADMIN_DATA_HISTORY = 'ADMIN_DATA_HISTORY',
  ADMIN_METER_DATA_INGESTION = 'ADMIN_METER_DATA_INGESTION',
  // Calculations
  ADMIN_FERRETS = 'ADMIN_FERRETS',
  ADMIN_LOADFLOW = 'ADMIN_LOADFLOW',
  ADMIN_VERSION_DETAILS = 'ADMIN_VERSION_DETAILS',
}

export const TableHeaders: { [key in PaginationType]: Type.DataTableHeader[] } = {
  [PaginationType.ADMIN_STATUS_OVERVIEW]: [
    { title: 'Tenant' },
    { title: 'Portfolio' },
    { title: 'Scenario' },
    { title: 'Latest GIS data' },
    { title: 'Latest meter data' },
    { title: 'Latest successful version' },
    { title: 'Latest successful simulation' },
    { title: 'Latest successful map update' },
    { title: 'Map data is less than one day old', className: 'text-center' },
    { title: 'Dashboard tests', className: 'text-center' },
  ],
  [PaginationType.ADMIN_TEST_OVERVIEW]: [
    { title: 'Tenant' },
    { title: 'Portfolio' },
    { title: 'Scenario' },
    { title: 'Map', className: 'text-center' },
    { title: 'Data quality', className: 'text-center' },
    { title: 'Network loading', className: 'text-center' },
    { title: 'Investment', className: 'text-center' },
    { title: 'Customer', className: 'text-center' },
    { title: 'N-1', className: 'text-center' },
    { title: 'CO₂e', className: 'text-center' },
    { title: 'Setup', className: 'text-center' },
    { title: 'New load', className: 'text-center' },
    { title: 'Download', className: 'text-center' },
    { title: 'Maintenance & Planning', className: 'text-center' },
    { title: 'Reinvestment', className: 'text-center' },
    { title: 'Run tests', className: 'text-right' },
  ],
  [PaginationType.ADMIN_FUNCTIONALITY_TESTS]: [
    { title: 'Name', sortKey: 'name' },
    { title: 'Tenant', sortKey: 'tenant' },
    { title: 'Portfolio', sortKey: 'portfolio' },
    { title: 'Scenario', sortKey: 'scenario' },
    { title: 'Timestamp', sortKey: 'test_run_timestamp' },
    { title: 'Duration', sortKey: 'duration' },
    { title: 'Tags', sortKey: 'tags' },
    { title: 'Success', sortKey: 'success' },
    { title: 'Message', sortKey: 'error_message' },
    { title: 'Dashboard version', sortKey: 'dashboard_version' },
    { title: 'Actions', className: 'text-right' },
  ],
  [PaginationType.ADMIN_TEST_HISTORY]: [
    { title: 'Timestamp', sortKey: 'test_run_timestamp' },
    { title: 'Duration' },
    { title: 'Tags' },
    { title: 'Success' },
    { title: 'Message' },
    { title: 'Dashboard version' },
  ],
  [PaginationType.ADMIN_DATA_HISTORY]: [
    { title: 'Timestamp', sortKey: 'test_run_timestamp' },
    { title: 'Value', sortKey: 'value' },
    { title: 'Version ID', sortKey: 'version_id' },
    { title: 'Simulation ID', sortKey: 'simulation_id' },
  ],
  [PaginationType.ADMIN_METER_DATA_INGESTION]: [
    { title: 'Date', sortKey: 'ingestion_date' },
    { title: 'Number of files', sortKey: 'nr_files_ingested' },
    { title: 'File size (MB)', sortKey: 'file_size_mb' },
    { title: 'Duration (s)', sortKey: 'ingestion_duration' },
    { title: 'Number of rows', sortKey: 'nr_rows' },
  ],
  [PaginationType.ADMIN_FERRETS]: [
    { title: 'Tenant' },
    { title: 'Type' },
    {
      title: 'Time',
      className: 'text-center',
      subTitles: [{ title: 'Start' }, { title: 'End' }, { title: 'Total' }],
    },
    { title: 'State', className: 'text-center' },
    { title: 'Logs', className: 'text-center' },
    { title: 'Actions', className: 'text-right' },
  ],
  [PaginationType.ADMIN_LOADFLOW]: [
    { title: 'Tenant' },
    { title: 'Steps' },
    { title: 'Misc' },
    {
      title: 'Time',
      className: 'text-center',
      subTitles: [{ title: 'Start' }, { title: 'End' }, { title: 'Total' }],
    },

    { title: 'State', className: 'text-center' },
    { title: 'Logs', className: 'text-center' },
    { title: 'Error message', className: 'text-center' },
    { title: 'Actions', className: 'text-right' },
  ],
  [PaginationType.ADMIN_VERSION_DETAILS]: [],
};

export { MaxMobileWidth, isDevelopment } from '@utiligize/shared/constants';

export enum FetchOptionsPageParam {
  FERRETS = 'ferrets',
  LOADFLOW = 'loadflow',
  VERSION_DETAILS = 'version-details',
}

export { Routes };
