import { createSelector } from 'reselect';
import {
  loadflowEnvironmentOptionsSelector,
  loadflowFlowOptionsSelectorFactory,
  workflowTenantsOptionsSelectorFactory,
} from 'modules/options/selectors';
import { FetchOptionsPageParam } from 'constants/index';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.calculations;

export const ferretsSelector = createSelector(
  rootSelector,
  (calculations: Calculations.Root): Calculations.Root['ferrets'] => calculations.ferrets
);

export const loadflowItemsSelector = createSelector(
  rootSelector,
  (calculations: Calculations.Root): Calculations.Root['loadflowItems'] => calculations.loadflowItems
);

export const tableFerretsWaitForDependenciesSelectorFactory = ({
  environment,
}: {
  environment: Layouts.Filters['environment'];
}) =>
  createSelector(
    [
      loadflowEnvironmentOptionsSelector,
      workflowTenantsOptionsSelectorFactory(`${FetchOptionsPageParam.FERRETS}_${environment}`),
    ],
    (loadflowEnvironmentOptions, workflowTenantsOptions): boolean => {
      return Boolean(!loadflowEnvironmentOptions || !workflowTenantsOptions);
    }
  );

export const tableLoadflowWaitForDependenciesSelectorFactory = ({
  environment,
}: {
  environment: Layouts.Filters['environment'];
}) =>
  createSelector(
    [
      loadflowEnvironmentOptionsSelector,
      loadflowFlowOptionsSelectorFactory(environment),
      workflowTenantsOptionsSelectorFactory(`${FetchOptionsPageParam.LOADFLOW}_${environment}`),
    ],
    (loadflowEnvironmentOptions, loadflowFlowOptions, workflowTenantsOptions): boolean => {
      return Boolean(!loadflowEnvironmentOptions || !loadflowFlowOptions || !workflowTenantsOptions);
    }
  );
