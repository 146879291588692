import moment from 'moment';
import _omit from 'lodash.omit';

export const dateFormat = (date: string, format = 'L') => {
  const offset = moment().utcOffset();
  return date && moment.utc(date).utcOffset(offset).format(format);
};

export const numberFormat = (
  number: number | null | undefined,
  options: Intl.NumberFormatOptions & { fallback?: string } = {}
): string => {
  if (typeof number !== 'number' || isNaN(number)) return options.fallback || '';
  return new Intl.NumberFormat(moment.locale(), {
    minimumFractionDigits: options.minimumFractionDigits ?? 0,
    maximumFractionDigits: options.maximumFractionDigits ?? 2,
  }).format(number);
};

export const isObject = (value: any) => {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
};

export { default as _cloneDeep } from 'lodash.clonedeep';
export { default as _keyBy } from 'lodash.keyby';
export { default as _merge } from 'lodash.merge';
export { default as _mergeWith } from 'lodash.mergewith';
export { default as _omit } from 'lodash.omit';
export { default as _pick } from 'lodash.pick';
export { default as _pickBy } from 'lodash.pickby';
export { default as _set } from 'lodash.set';
export { default as _throttle } from 'lodash.throttle';
export { default as _snakecase } from 'lodash/snakeCase';
export { default as _uniqBy } from 'lodash.uniqby';
export { default as _isUndefined } from 'lodash.isundefined';
