import styled from 'styled-components';
import React, { useCallback } from 'react';
import { Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import { UserService } from '@utiligize/shared/services';
import { Button } from '@utiligize/shared/components';
import { IconLogout, IconUserInfo, IconUser } from '@utiligize/shared/resources';
import { getStorageItem } from 'utils';
import { StorageKeys } from 'constants/index';

interface Claims {
  firstName: string;
  lastName: string;
  email: string;
  tenants: string[];
}

const PopoverUserSettings: React.FC = () => {
  const claims: Claims | null = getStorageItem(StorageKeys.USER_CLAIMS);

  const handleLogoutClick = useCallback((): Promise<void> => UserService.doLogout(), []);

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose
      overlay={
        <StyledPopover id="popover-user-settings">
          <Popover.Content>
            <div className="d-flex align-items-center my-1">
              <IconUserInfo width={30} height={30} />
              <div className="ml-2">
                {claims?.firstName} {claims?.lastName}
                <br />
                {claims?.email}
              </div>
            </div>
            <Dropdown.Divider />
            <StyledControlsContainer>
              <Button
                icon={<IconLogout />}
                labelKey="Logout"
                onClick={handleLogoutClick}
                variant="danger"
                className="w-100"
              />
            </StyledControlsContainer>
          </Popover.Content>
        </StyledPopover>
      }
    >
      <Button
        marginLeft
        variant="primary"
        icon={<IconUser />}
        aria-label="User settings"
        data-marker="header__user_settings"
      />
    </OverlayTrigger>
  );
};

const StyledPopover = styled(Popover)`
  width: 325px;
  max-width: 325px;
  z-index: 10001;
`;

const StyledControlsContainer = styled.div`
  display: flex;
  column-gap: 10px;
`;

export default PopoverUserSettings;
